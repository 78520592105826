@import './app.scss';
@import './navbar.scss';
@import './footer.scss';


@import './home.scss';
@import './about.scss';
@import './service.scss';
@import './location.scss';

#app .container {
    background-color: #f8f9fa;
}


body { 
    margin: 0;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.brown {
    color: #911;
}

.dark-brown {
    color: #6d0b0b
}

.blue {
    color: blue;
}

.red {
    color: red;
}

.dark-gray {
    color: rgb(73, 73, 73);
}