#footer {
    background-color: rgb(73, 73, 73);
    width: 100%;
    height: 45px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        color: white;
    }
}