#app { 
    padding: 40px;
    background-color: lightgray;        
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: auto;

    .container { 
        background-color: white;
        padding: 0px;
    }
}

@media (max-width: 992px) {
    #app { 
        padding: unset;
        .container { 
            margin: 0;
            max-width: unset;
        }
    }
}