nav {
    text-align: center;
    display: flex;

    .logo {
        position: relative;
        background-color: lightgray;

        img {
            vertical-align: middle;
            width: 339px;
            height: 92px;
        }

        span {
            position: absolute;
            left: 20px;
            bottom: 0px;
            color: white;
            font-size: 40px;
            font-family: 'Open Sans',sans-serif;
            font-weight: 700;
            z-index: 999;
        }
    }
    
    
    .menu {
        width: 100%;
        display: flex;
        position: relative;
        background-color: lightgray;

        >img {
            position: absolute;
            bottom: 0px;
            height: 45px;
            width: 100%;
        }
    }
    
      
    ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 0px;
        padding-right: 10px;
        height: 45px;
        position: absolute;
        bottom: 0px;
        width: 100%;    
        
        li {
            display: flex;
            padding: 6px 20px;
        }
    }
    
    nav > ul {
        padding: 4px 16px;
    }  
    
    a {
        color: white;
        text-decoration: none;
        // padding: 5px 10px;
        font-size: 14px;
        font-weight: 600;
    }
      
    .active, a:hover {
        color: white;
        text-decoration: none;
        background-color: #590a0a;
        border: 1px solid #931010;
    }
}

@media (max-width: 992px) {
    nav {
        text-align: unset;
        display: unset;
        .logo {
            background-color: unset;
            span {
                font-size: 35px;
            }
        }
        ul {
            justify-content: space-evenly;
            padding-left: 0;
        }
    }
}

@media (max-width: 768px) { 
    nav {
        ul {
            flex-wrap: wrap;
            padding-right: unset;
            li {
                padding: 0 5px;
            }
        }
    }
}