#about {
    padding: 10px;

    h2 {
        font-size: 28px;
    }

    .top, .main {
        padding: 30px;
    }
}

  