#services {
    padding: 10px;

    h2 {
        font-size: 28px;
    }
    
    h3 {
        font-size: 24px;
    }

    .main {
        padding: 30px;
    }
    
    .description {
        font-size: 18px;
    }
}

