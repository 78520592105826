#location {
    padding: 10px;

    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 24px;
    }

    .main {
        padding: 30px;
    }

    .font-19 {
        font-size: 19px;
    }

    .font-18 {
        font-size: 18px;
    }

    .btn {
        border-radius: 50px;
    }
}